import React from "react";
import { PhoneIcon, ArrowSmRightIcon } from "@heroicons/react/outline";
import { ChipIcon, SupportIcon } from "@heroicons/react/solid";

import supportImg from "../assets/support.jpg";

const SupportCard = ({title}) => {
  return (
    <div className="bg-white rounded-xl shadow-2xl ">
      <div className="p-8">
        <PhoneIcon className="w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]" />
        <h3 className="font-bold text-2xl my-6">{title}</h3>
        <p className="text-gray-600 text-xl">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim quos
          officiis voluptas aperiam nemo, iste illum!
        </p>
      </div>

      <div className="bg-slate-100 pl-8 py-4">
        <p className="flex items-center text-indigo-600 cursor-pointer">
          Contact Us <ArrowSmRightIcon className="w-5 ml-2" />
        </p>
      </div>
    </div>
  );
};

const Support = () => {
  return (
    <div name="support" className="w-full mt-24">
      <div className="w-full h-[700px] bg-gray-900/90 absolute">
        <img
          className="w-full h-full object-cover mix-blend-overlay"
          src={supportImg}
          alt=""
        />
      </div>

      <div className="max-w-[1240px] mx-auto text-white relative">
        <div className="px-4 py-12">
          <h2 className="pt-8 text-slate-300 uppercase text-center text-2xl">
            Support{" "}
          </h2>
          <h3 className="text-4xl font-bold py-6 text-center">
            Finding the right team
          </h3>
          <p className="py-4 text-2xl text-slate-300 text-center">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Praesentium recusandae officia cum iure doloribus nihil aperiam.
            Odio possimus, accusantium perferendis fugit minus cupiditate
            eligendi eum, accusamus facere magnam ipsa quas!
          </p>
        </div>

        <div
          className="grid grid-cols-1 lg:grid-cols-3 relative 
            gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black"
        >
          <SupportCard title="Sales"/>
          <SupportCard title="Technical Support"/>
          <SupportCard title="Media Inquiries"/>
          {/* <div className="bg-white rounded-xl shadow-2xl ">
            <div className="p-8">
              <PhoneIcon className="w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]"/>
              <h3 className="font-bold text-2xl my-6">Sales</h3>
              <p className="text-gray-600 text-xl">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim
                quos officiis voluptas aperiam nemo, iste illum!
              </p>
            </div>

            <div className="bg-slate-100 pl-8 py-4">
              <p className="flex items-center text-indigo-600">
                Contact Us <ArrowSmRightIcon className="w-5 ml-2"/>
              </p>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-2xl ">
            <div className="p-8">
              <SupportIcon className="w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]"/>
              <h3 className="font-bold text-2xl my-6">Technical Support</h3>
              <p className="text-gray-600 text-xl">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim
                quos officiis voluptas aperiam nemo, iste illum!
              </p>
            </div>

            <div className="bg-slate-100 pl-8 py-4">
              <p className="flex items-center text-indigo-600">
                Contact Us <ArrowSmRightIcon className="w-5 ml-2"/>
              </p>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-2xl ">
            <div className="p-8">
              <ChipIcon className="w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]"/>
              <h3 className="font-bold text-2xl my-6">Media Inquiries</h3>
              <p className="text-gray-600 text-xl">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim
                quos officiis voluptas aperiam nemo, iste illum!
              </p>
            </div>

            <div className="bg-slate-100 pl-8 py-4">
              <p className="flex items-center text-indigo-600">
                Contact Us <ArrowSmRightIcon className="w-5 ml-2"/>
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Support;
